<template>
  <Accordion header="Fuente">
    <div
      v-if="!loading && !lectureMode"
      class="flex flex-col gap-3 justify-start text-left shadow-sm border rounded-xl p-5"
    >
      <h3 class="text-left text-gray-800 font-bold mb-4">
        Cómo se identificó el hallazgo
      </h3>
      <div>
        <label
          for="vocerias"
          class="text-lg text-gray-400 text-left font-bold mr-4"
        >
          4.1. Vocerías</label
        >
        <a-switch
          id="vocerias"
          @change="onChangeSwitch('vocerias', $event)"
          v-model="switches.vocerias"
        ></a-switch>
      </div>
      <div>
        <label
          for="quejas"
          class="text-lg text-gray-400 text-left font-bold mr-4"
        >
          4.2. Quejas</label
        >
        <a-switch
          id="quejas"
          @change="onChangeSwitch('quejas', $event)"
          v-model="switches.quejas"
        ></a-switch>
      </div>
      <div>
        <label
          for="revision"
          class="text-lg text-gray-400 text-left font-bold mr-4"
        >
          4.3. Revisión de contratos de adhesión</label
        >
        <a-switch
          id="revision"
          @change="onChangeSwitch('revision', $event)"
          v-model="switches.revision"
        ></a-switch>
      </div>
      <div>
        <label
          for="practicas-clausulas"
          class="text-lg text-gray-400 text-left font-bold mr-4"
        >
          4.4. Prácticas y cláuslas</label
        >
        <a-switch
          id="practicas-clausulas"
          @change="onChangeSwitch('practicas_clausulas', $event)"
          v-model="switches.practicas_clausulas"
        ></a-switch>
      </div>
      <div>
        <label
          for="otra"
          class="text-lg text-gray-400 text-left font-bold mr-4"
        >
          4.5. Otra</label
        >
        <a-switch
          id="otra"
          @change="onChangeSwitch('otra', $event)"
          v-model="switches.otra"
        ></a-switch>
      </div>
    </div>
    <div
      v-if="loading"
      class="spinner w-full h-full top-0 flex items-center justify-center"
    >
      <a-spin class="">
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <div class="flex flex-col gap-8 mt-8">
      <Accordion header=" 4.1. Vocerías" v-if="switches.vocerias">
        <BaseTextarea
          required="true"
          :disabled="lectureMode"
          v-model="spokesperson.spokesperson_description"
          maxlength="1000"
          label="Descripción de los casos de vocerías interpuestos por los consumidores, que dan origen a la recomendación que formule el defensor"
        />
        <div class="w-1/2">
          <BaseInput
            required="true"
            :disabled="lectureMode"
            label="Información estadística consolidada"
            numeric
            v-model="spokesperson.spokesperson_cases"
            maxlength="5"
          />
        </div>
        <div
          class="w-11/12 grid grid-cols-1 gap-1"
          v-if="spokesperson.loadFiles.length > 0"
        >
          <h1 class="text-left text-gray-400 font-bold text-sm mt-5 mb-0">
            Archivos de soporte precargados
          </h1>
          <BaseDownload
            v-for="(option, index) in spokesperson.loadFiles"
            :value="option.file_name"
            :key="index"
            :url_download="option.file"
          />
        </div>
        <DragDrop
          v-if="!lectureMode"
          label="Anexar archivos de soporte:"
          buttonLabel="SELECCIONE SUS ARCHIVOS"
          id="vocerias-files"
          :fileList="spokesperson.files"
          @files-handler="setFiles(1, $event)"
          maxMB="20"
        />
      </Accordion>
      <Accordion header=" 4.2. Quejas " v-if="switches.quejas">
        <BaseTextarea
          required="true"
          :disabled="lectureMode"
          maxlength="1000"
          label="Descripción"
          v-model="complaints.complaint_description"
        />
        <div class="w-1/2">
          <BaseInput
            required="true"
            :disabled="lectureMode"
            v-model="complaints.complaint_cases"
            label="Número, relacionando de manera consolidada las quejas que originan la recomendación"
            numeric
            maxlength="5"
          />
        </div>
        <div
          class="w-11/12 grid grid-cols-1 gap-1"
          v-if="complaints.loadFiles.length > 0"
        >
          <h1 class="text-left text-gray-400 font-bold text-sm mt-5 mb-0">
            Archivos de soporte precargados
          </h1>
          <BaseDownload
            v-for="(option, index) in complaints.loadFiles"
            :value="option.file_name"
            :key="index"
            :url_download="option.file"
          />
        </div>
        <DragDrop
          v-if="!lectureMode"
          label="Anexar archivos de soporte:"
          buttonLabel="SELECCIONE SUS ARCHIVOS"
          id="quejas-files"
          :fileList="complaints.files"
          @files-handler="setFiles(2, $event)"
          maxMB="20"
        />
      </Accordion>
      <Accordion
        header=" 4.3. Revisión de contratos de adhesión"
        v-if="switches.revision"
      >
        <div class="relative box-border">
          <h6 class="text-left m-0 text-md font-bold text-gray-500 mb-1">
            Relación de aquellos contratos de adhesión identificando los
            formatos o documentos analizados
          </h6>
          <div class="border rounded-xl">
            <div class="grid grid-cols-2 p-3 gap-3 text-left">
              <h3 class="text-gray-500 font-bold">
                Nombre de contrato o documento
              </h3>
              <h3 class="text-gray-500 font-bold">
                Identificación de la clausula
              </h3>
            </div>
            <a-divider class="my-0" />
            <div class="p-4 mt-0">
              <div
                class="flex justify-center items-center gap-3 relative"
                v-for="(row, idx) in contracts.contracts"
                :key="idx"
              >
                <BaseInput
                  required="true"
                  v-model="contracts.contracts[idx].name"
                  :disabled="lectureMode"
                />
                <BaseInput
                  required="true"
                  v-model="contracts.contracts[idx].identification"
                  :disabled="lectureMode"
                />

                <a-button
                  @click="deleteRow(idx)"
                  icon="delete"
                  class="mt-4 border-none"
                  size="large"
                ></a-button>
              </div>
              <div
                class="rounded-xl border p-2 w-full flex gap-4 items-center mt-4"
              >
                <a-button
                  class="border-none"
                  id="add-revision"
                  type="primary"
                  shape="circle"
                  size="large"
                  icon="plus"
                  @click="addRow()"
                ></a-button>
                <label
                  for="add-revision"
                  class="text-left texl-gray-800 font-bold"
                  >Añadir</label
                >
              </div>
            </div>
          </div>
          <BaseTextarea
            required="true"
            :disabled="lectureMode"
            label="Conclusiones"
            maxlength="1000"
            v-model="contracts.contract_conclusion"
          />
          <div
            class="w-11/12 grid grid-cols-1 gap-1"
            v-if="contracts.loadFiles.length > 0"
          >
            <h1 class="text-left text-gray-400 font-bold text-sm mt-5 mb-0">
              Archivos de soporte precargados
            </h1>
            <BaseDownload
              v-for="(option, index) in contracts.loadFiles"
              :value="option.file_name"
              :key="index"
              :url_download="option.file"
            />
          </div>
          <DragDrop
            v-if="!lectureMode"
            label="Anexar archivos de soporte:"
            buttonLabel="SELECCIONE SUS ARCHIVOS"
            id="revision-files"
            :fileList="contracts.files"
            @files-handler="setFiles(3, $event)"
            maxMB="20"
          />
        </div>
      </Accordion>
      <Accordion
        header=" 4.4. Práctica y cláusulas"
        v-if="switches.practicas_clausulas"
      >
        <BaseTextarea
          required="true"
          :disabled="lectureMode"
          label="Descripción: previa identificación de si es indebida, ilegal, insegura o abusiva"
          maxlength="1000"
          v-model="practice.practice_description"
        />
        <div class="w-1/2 xl:w-1/3">
          <BaseInput
            required="true"
            :disabled="lectureMode"
            label="Número"
            numeric
            maxlength="5"
            v-model="practice.practice_number"
          />
        </div>
        <BaseInput
          required="true"
          :disabled="lectureMode"
          label="Identificación de norma vulnerada"
          v-model="practice.practice_identification"
        />
        <div
          class="w-11/12 grid grid-cols-1 gap-1"
          v-if="practice.loadFiles.length > 0"
        >
          <h1 class="text-left text-gray-400 font-bold text-sm mt-5 mb-0">
            Archivos de soporte precargados
          </h1>
          <BaseDownload
            v-for="(option, index) in practice.loadFiles"
            :value="option.file_name"
            :key="index"
            :url_download="option.file"
          />
        </div>
        <DragDrop
          v-if="!lectureMode"
          label="Anexar archivos de soporte:"
          buttonLabel="SELECCIONE SUS ARCHIVOS"
          id="practice-files"
          :fileList="practice.files"
          @files-handler="setFiles(4, $event)"
          maxMB="20"
        />
      </Accordion>
      <Accordion header=" 4.5. Otra" v-if="switches.otra">
        <BaseInput
          required="true"
          label="Nombre"
          maxlength="50"
          v-model="other.other_name"
        />
        <BaseTextarea
          required="true"
          :disabled="lectureMode"
          label="Breve descripción"
          maxlength="100"
          v-model="other.other_description"
        />
        <div
          class="w-11/12 grid grid-cols-1 gap-1"
          v-if="other.loadFiles.length > 0"
        >
          <h1 class="text-left text-gray-400 font-bold text-sm mt-5 mb-0">
            Archivos de soporte precargados
          </h1>
          <BaseDownload
            v-for="(option, index) in other.loadFiles"
            :value="option.file_name"
            :key="index"
            :url_download="option.file"
          />
        </div>
        <DragDrop
          v-if="!lectureMode"
          label="Anexar archivos de soporte:"
          buttonLabel="SELECCIONE SUS ARCHIVOS"
          id="other-files"
          :fileList="other.files"
          @files-handler="setFiles(5, $event)"
          maxMB="20"
        />
      </Accordion>
    </div>
  </Accordion>
</template>

<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import BaseRadio from "../../../../UI/BaseInput/BaseRadio.vue";
export default {
  props: {
    finishing: { type: Boolean, default: false },
    saveSignal: { type: Boolean, default: false },
    lectureMode: { type: Boolean, default: false },
  },
  components: { Accordion, BaseRadio },
  data() {
    return {
      id: "",
      spokesperson: {
        spokesperson_description: "",
        spokesperson_cases: "",
        files: [],
        loadFiles: [],
        recommendation_file_type: 1,
      },
      complaints: {
        complaint_description: "",
        complaint_cases: "",
        files: [],
        loadFiles: [],
        recommendation_file_type: 2,
      },
      contracts: {
        contract_conclusion: "",
        files: [],
        loadFiles: [],
        recommendation_file_type: 3,
        contracts: [],
      },
      practice: {
        practice_description: "",
        practice_number: "",
        practice_identification: "",
        files: [],
        loadFiles: [],
        recommendation_file_type: 4,
      },
      other: {
        other_name: "",
        other_description: "",
        files: [],
        loadFiles: [],
        recommendation_file_type: 5,
      },
      loading: false,
      switches: {
        vocerias: false,
        otra: false,
        practicas_clausulas: false,
        revision: false,
        quejas: false,
      },
      contractsWarning: false,
    };
  },
  created() {
    this.getStep();
  },
  methods: {
    async getStep(loading = true) {
      this.loading = loading;
      this.resetLoadFiles();
      let { error, data } = await this.$api.getSteps(
        this.$route.query.recommendation_code,
        4
      );
      if (data) {
        const result = data.results[0];
        this.spokesperson.spokesperson_cases = result.spokesperson_cases;
        this.spokesperson.spokesperson_description =
          result.spokesperson_description != "null"
            ? result.spokesperson_description
            : null;

        this.complaints.complaint_cases = result.complaint_cases;
        this.complaints.complaint_description =
          result.complaint_description != "null"
            ? result.complaint_description
            : null;

        this.practice.practice_description =
          result.practice_description != "null"
            ? result.practice_description
            : null;
        this.practice.practice_number = result.practice_number;
        this.practice.practice_identification = result.practice_identification;

        this.other.other_name = result.other_name;
        this.other.other_description =
          result.other_description != "null" ? result.other_description : null;

        this.contracts.contract_conclusion =
          result.contract_conclusion != "null"
            ? result.contract_conclusion
            : null;
        this.contracts.contracts = result.contracts;

        this.id = data.results[0].id;

        this.setLoadFiles(result.files);
        this.loadSelectedSwitches();
        this.loading = false;
        this.$forceUpdate();
      }
      if (error) {
        this.showToast("error", "Ocurrió un error obteniendo la información.");
        this.loading = false;
      }
    },
    addRow() {
      const length = this.contracts.contracts.length;

      if (length === 0)
        this.contracts.contracts.push({ name: "", identification: "" });
      else if (
        this.contracts.contracts[length - 1].name !== "" &&
        this.contracts.contracts[length - 1].identification !== ""
      )
        this.contracts.contracts.push({ name: "", identification: "" });
      else this.contractsWarning = true;
    },
    deleteRow(idx) {
      this.contracts.contracts.splice(idx, 1);
      this.$forceUpdate();
    },
    isEmptyForm(form) {
      const errors = []
      Object.keys(form).map((key) => {
        if (Array.isArray(form[key]) && form[key].length === 0) {
          if (key === 'loadFiles' && form.files.length === 0) {
            errors.push(key)
          }
          if (key === 'files' && form.loadFiles.length === 0) {
            errors.push(key)
          }
        } else if (["", null, undefined].includes(form[key])) {
          errors.push(key)
        }
      })
      return errors.length;
    },
    showEmptyMessage(section) {
      this.showToast("info", `En el Paso 4 en la sección de ${section} no se completó la información`)
    },
    saveStep() {
      let idx = 1;
      let result = false;
      for (const element in this.switches) {
        if (this.switches[element]) {
          switch (element) {
            case "vocerias":
              if (this.isEmptyForm(this.spokesperson) > 0) {
                this.showEmptyMessage("Vocerías")
              }
              result = this.saveSectionChecked(this.spokesperson);
              break;
            case "quejas":
              if (this.isEmptyForm(this.complaints) > 0) {
                this.showEmptyMessage("Quejas")
              }
              result = this.saveSectionChecked(this.complaints);
              break;
            case "revision":
              if (this.isEmptyForm(this.contracts) > 0) {
                this.showEmptyMessage("Revisión de contratos de adhesión")
              }
              result = this.saveSectionChecked(this.contracts);
              break;
            case "practicas_clausulas":
              if (this.isEmptyForm(this.practice) > 0) {
                this.showEmptyMessage("Prácticas y cláusulas")
              }
              result = this.saveSectionChecked(this.practice);
              break;
            case "otra":
              if (this.isEmptyForm(this.other) > 0) {
                this.showEmptyMessage("Otra")
              }
              result = this.saveSectionChecked(this.other);
              break;
          }
        }
        idx += 1;
      }
      if (result) this.showToast("success", "Guardando como borrador...");
      else
        this.showToast("info", "Algunos datos no se guardaron correctamente.");
    },
    async saveSectionChecked(paramSection) {
      let form = {};
      let section = JSON.parse(JSON.stringify(paramSection));
      paramSection.files.forEach((file, index) => {
        form[`files[${index}]recommendation_file_type`] =
          section.recommendation_file_type + "";
      });
      let files = paramSection.files;
      if (section?.contracts) {
        section.contracts.forEach((file, index) => {
          if (
            section.contracts[index].name != "" &&
            section.contracts[index].identification != ""
          )
            (form[`contracts[${index}]name`] = section.contracts[index].name),
              (form[`contracts[${index}]identification`] =
                section.contracts[index].identification);
        });
        delete section.contracts;
      }
      delete section.recommendation_file_type;
      delete section.files;
      delete section.loadFiles;
      for (const item in section) {
        if (section[item]) form[item] = section[item] + "";
      }
      let { data, error } = await this.$api.recommendationSteps(
        this.$route.query.recommendation_code,
        4,

        {
          form,
          files: files.length > 0 ? files : null,
        },
        this.id
      );
      if (data) {
        return true;
      } else if (error) {
        return false;
      }
    },
    setFiles(id, files) {
      switch (id) {
        case 1:
          this.spokesperson.files = files;
          break;
        case 2:
          this.complaints.files = files;
          break;
        case 3:
          this.contracts.files = files;
          break;
        case 4:
          this.practice.files = files;
          break;
        case 5:
          this.other.files = files;
          break;
      }
    },

    loadSelectedSwitches() {
      if (
        this.spokesperson.spokesperson_cases !== null ||
        this.spokesperson.spokesperson_description !== null ||
        this.spokesperson.loadFiles.length > 0
      )
        this.switches.vocerias = true;

      if (
        this.complaints.complaint_cases !== null ||
        this.complaints.complaint_description !== null ||
        this.complaints.loadFiles.length > 0
      )
        this.switches.quejas = true;

      if (
        this.practice.practice_description !== null ||
        this.practice.practice_number !== null ||
        this.practice.practice_identification !== null ||
        this.practice.loadFiles.length > 0
      )
        this.switches.practicas_clausulas = true;
      if (
        this.other.other_name !== null ||
        this.other.other_description !== null ||
        this.other.loadFiles.length > 0
      )
        this.switches.otra = true;
      if (
        this.contracts.contracts?.length > 0 ||
        this.contracts.contract_conclusion !== null ||
        this.contracts.loadFiles.length > 0
      )
        this.switches.revision = true;
    },
    setLoadFiles(files) {
      files.forEach((file) => {
        switch (file.recommendation_file_type) {
          case 1:
            this.spokesperson.loadFiles.push(file);
            break;
          case 2:
            this.complaints.loadFiles.push(file);
            break;
          case 3:
            this.contracts.loadFiles.push(file);
            break;
          case 4:
            this.practice.loadFiles.push(file);
            break;
          case 5:
            this.other.loadFiles.push(file);
            break;
        }
      });
    },
    resetLoadFiles() {
      this.spokesperson.files = [];
      this.complaints.files = [];
      this.contracts.files = [];
      this.practice.files = [];
      this.other.files = [];
    },
    onChangeSwitch(id, change) {
      this.switches[id] = change;
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
  beforeDestroy() {
    if (!this.lectureMode) this.saveStep();
  },
  watch: {
    saveSignal: function (value) {
      if (value) {
        this.saveStep();
        setTimeout(() => {
          this.getStep(false);
        }, 1500);
      }
    },
  },
};
</script>
